import React, {Suspense, useEffect} from 'react';
import {Spinner} from '@lookiero/aurora';
import {QUIZ_ID, urlHasQuiz, scrollToQuiz} from './scrollToQuiz';

const MIN_HEIGHT_WRAPPER = 400;
const Quiz = React.lazy(() => import('./Quiz'));

const LazyQuiz: React.FC<{renderQuiz?: boolean}> = ({renderQuiz = true}) => {
  useEffect(() => {
    if (urlHasQuiz()) scrollToQuiz();
  }, []);

  return (
    <>
      <div id={QUIZ_ID} style={{minHeight: MIN_HEIGHT_WRAPPER, alignContent: 'center'}}>
        <Suspense fallback={<Spinner />}>{renderQuiz && <Quiz />}</Suspense>
      </div>
    </>
  );
};

export {LazyQuiz};
