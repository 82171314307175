import React from 'react';
import {View, Layout, Box, SPACE, useDevice, ViewProperties} from '@lookiero/aurora';

import {styles} from './Section.styles';

type SectionProps = ViewProperties;

export const Section: React.FC<SectionProps> = ({children, ...rest}) => {
  const {screen} = useDevice();

  return (
    <View {...rest}>
      <Layout
        as="section"
        style={styles.layout}
        fullWidth={screen.L}
        paddingVertical={{L: SPACE.SPACE_16, default: SPACE.SPACE_8}}
      >
        <Box size={{L: '2/3'}} style={styles.content}>
          {children}
        </Box>
      </Layout>
    </View>
  );
};
