import React from 'react';

import styles from './LazyAnimation.module.css';

interface LazyAnimationProps {
  element: (node?: Element | null | undefined) => void;
  isVisible: boolean;
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  elementStyle?: React.CSSProperties;
  disableAnimation?: boolean;
}

export const LazyAnimation: React.FC<LazyAnimationProps> = ({
  element,
  isVisible,
  containerStyle,
  elementStyle,
  children,
  disableAnimation = false,
}) => {
  const animationStyle = isVisible ? styles.elementEase : styles.elementLazy;

  return (
    <div ref={element} className={styles.container} style={containerStyle}>
      <div className={`${styles.element} ${disableAnimation ? '' : animationStyle}`} style={elementStyle}>
        {children}
      </div>
    </div>
  );
};
