import React, {useMemo, useRef, useEffect, useState, useCallback} from 'react';
import {useDevice} from '@lookiero/aurora';

import {isSeoRender} from '@/modules/SEO';
import {useIntersectionObserver} from '@/hooks';
import {LazyAnimation} from '@/components/atoms/LazyAnimation';
import {isAndroidInAppBrowser} from '@/utils/inAppBrowser';
import styles from './Video.module.css';

const VIDEO_CONFIG: React.VideoHTMLAttributes<HTMLVideoElement> = {
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
  disableRemotePlayback: true,
} as const;

const ANDROID_VIDEO_CONFIG: React.VideoHTMLAttributes<HTMLVideoElement> = {
  ...VIDEO_CONFIG,
  // transparent poster to avoid overlay play icon in android webview
  // this must be added only for android because has performance issues with safari
  poster: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
} as const;

interface VideoProps {
  src: string | {mobile: string; desktop: string};
  lazyLoad?: boolean;
  style?: React.CSSProperties;
  onError?: () => void;
  fullHeight?: boolean;
  height: number;
  width: number;
  easeIn?: boolean;
  resetOnViewport?: boolean;
}

export const Video: React.FC<VideoProps> = ({
  src,
  lazyLoad = false,
  style,
  onError,
  fullHeight = false,
  height,
  width,
  easeIn = false,
  resetOnViewport = false,
}) => {
  const [resourceReady, setResourceReady] = useState(false);

  const {ref, inView, firstView} = useIntersectionObserver();

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isSeoRender) return;

    if (inView) {
      if (videoRef.current && resetOnViewport) {
        videoRef.current.currentTime = 0;
      }
      void videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [inView, resetOnViewport]);

  const {screen} = useDevice();

  const videoUrl = useMemo(() => {
    if (typeof src === 'string') return src;
    return screen.L ? src.desktop : src.mobile;
  }, [src, screen]);

  const paddingTop = `${(height / width) * 100}%`;
  const renderElement = !lazyLoad || (lazyLoad && firstView);
  const videoConfig = isAndroidInAppBrowser() ? ANDROID_VIDEO_CONFIG : VIDEO_CONFIG;

  const handleResourceReady = useCallback(() => {
    setResourceReady(true);
  }, []);

  return (
    <LazyAnimation
      element={ref}
      disableAnimation={!easeIn}
      isVisible={renderElement && resourceReady}
      elementStyle={fullHeight ? {height: '100%'} : {paddingTop}}
    >
      <video
        key={videoUrl}
        ref={videoRef}
        {...videoConfig}
        className={styles.video}
        style={style}
        onError={onError}
        height={height}
        width={width}
        onLoadedData={handleResourceReady}
      >
        {renderElement && <source src={videoUrl} type="video/mp4" />}
      </video>
    </LazyAnimation>
  );
};
