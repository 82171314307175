import React, {FC, useMemo} from 'react';
import {useScrollLock} from '@/hooks/useScrollLock';
import {Image, Modal, ScrollView, useDevice} from '@lookiero/aurora';
import {useIntl} from '@/modules/I18N';

import {getBrandsFromSite} from '../../services/getBrandsFromSite';
import {IDS, TEXT, BRANDS_DEF} from './BrandsModal.definition';
import {styles} from './BrandsModal.styles';

interface BrandsModalProps {
  isVisible: boolean;
  onClose: () => void;
}

export const BrandsModal: FC<BrandsModalProps> = ({isVisible, onClose}) => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();
  useScrollLock(isVisible);

  const brands = useMemo(() => getBrandsFromSite().map(brand => BRANDS_DEF[brand]), []);

  const modalStyles = !screen.L ? (isVisible ? styles.modalMobileVisible : styles.modalMobileNotVisible) : null;

  return (
    <Modal
      nativeID={IDS.MODAL_ID}
      title={formatMessage(TEXT.MODAL_TITLE)}
      description={formatMessage(TEXT.MODAL_DESCRIPTION)}
      isVisible={isVisible}
      onClose={onClose}
      style={modalStyles}
    >
      <ScrollView contentContainerStyle={styles.content}>
        {brands.map(brand => (
          <Image style={styles.img} alt={brand.name} key={brand.name} {...brand.image} />
        ))}
      </ScrollView>
    </Modal>
  );
};
